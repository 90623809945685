import React from "react"
import { Box } from "theme-ui"

// import app components
import Textarea from "../Textarea"
import BackgroundImage from "../BackgroundImage"
import Edges from "../Edges"

const Introduction = (props) => {
  const { image, text, backgroundcolor } = props

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: ["flex", "flex", "grid"],
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr",
          flexWrap: "wrap-reverse",
          alignItems: "center",
          minHeight: [null, null, 500],
          bg: backgroundcolor,
          "p:last-child": {
            mb: [16, 18, 24],
          },
        }}
      >
        <Box
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: 2,
            gridRowStart: 1,
            gridRowEnd: 2,
            left: 0,
            position: ["relative", null, "absolute"],
            height: [200, 300, "auto"],
            width: "100%",
            order: 1,
            top: [null, null, 0],
            bottom: [null, null, 0],
          }}
        >
          {image && <BackgroundImage image={image} />}
        </Box>
        <Box
          sx={{
            gridColumnStart: 2,
            gridColumnEnd: 3,
            gridRowStart: 1,
            gridRowEnd: 2,
            width: "100%",
            height: "100%",
            px: [0, 30],
            py: [40, 20],
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            maxWidth: "700px",
          }}
        >
          <Edges size="md">{text && <Textarea content={text} />}</Edges>
        </Box>
      </Box>
    </>
  )
}
export default Introduction
