import React from "react"

import { Link } from "gatsby"

import { Box, Text, IconButton } from "theme-ui"
import {
  ChevronLeft,
  ChevronRight,
  ChevronDoubleRight,
  ChevronDoubleLeft,
  DotsHorizontal,
} from "mdi-material-ui"

const SimplePagination = ({ numberOfPages, basePath, page }) => {
  const items = []

  if (numberOfPages < 2) {
    return null
  }

  for (let i = 1; i <= numberOfPages; i++) {
    let pathname = basePath

    if (i > 1) {
      pathname = `${basePath}${i}`
    }

    items.push(
      <Link to={pathname} key={i}>
        <Text
          as="li"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "23px",
            height: "23px",
            m: "14px 8px 14px 0",
            borderRadius: "6px",

            color: page === i ? "white" : "black",
            bg: page === i ? "primary" : "white",

            "&:hover": {
              color: "white",
              bg: "primary",
            },
          }}
        >
          {i}
        </Text>
      </Link>
    )
  }

  return (
    <Box sx={{ mt: "30px" }}>
      <nav>
        <Text
          as="ul"
          sx={{
            width: "fit-content",
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#FFF",
            borderRadius: 6,
            padding: "10px 10px 10px 15px",
            m: "0 auto",
          }}
        >
          {page < 2 ? (
            <IconButton sx={{ height: 30 }}>
              <ChevronLeft
                style={{
                  cursor: "pointer",
                  height: 22,
                  width: 22,
                  fill: "white",
                  background: "#DBDBDB",
                  border: "unset",
                  borderRadius: "50%",
                  marginRight: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled
              />
            </IconButton>
          ) : page === 2 ? (
            <Link to={basePath}>
              <IconButton sx={{ height: 30 }}>
                <ChevronLeft
                  style={{
                    cursor: "pointer",
                    fill: "primary",
                    border: "1px solid",
                    borderColor: "#DBDBDB",
                    borderRadius: "50%",
                    marginRight: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </IconButton>
            </Link>
          ) : (
            <Link to={basePath + (page - 1)}>
              <IconButton sx={{ height: 30 }}>
                <ChevronLeft
                  style={{
                    cursor: "pointer",
                    fill: "primary",
                    border: "1px solid",
                    borderColor: "#DBDBDB",
                    borderRadius: "50%",
                    marginRight: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </IconButton>
            </Link>
          )}

          {items}

          {page < numberOfPages ? (
            <Link to={basePath + (page + 1)}>
              <IconButton sx={{ height: 30 }}>
                <ChevronRight
                  style={{
                    cursor: "pointer",
                    fill: "primary",
                    border: "1px solid",
                    borderColor: "#DBDBDB",
                    borderRadius: "50%",
                    marginRight: 16,
                    marginLeft: 12,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </IconButton>
            </Link>
          ) : (
            <IconButton sx={{ height: 30 }}>
              <ChevronRight
                style={{
                  cursor: "pointer",
                  height: 22,
                  width: 22,
                  fill: "white",
                  background: "#DBDBDB",
                  border: "unset",
                  borderRadius: "50%",
                  marginLeft: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled
              />
            </IconButton>
          )}
        </Text>
      </nav>
    </Box>
  )
}

export default SimplePagination
