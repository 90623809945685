import React from "react"
import { graphql } from "gatsby"
import { Grid, Box, IconButton, Heading, Text } from "theme-ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import moment from "moment"
import Parser from "html-react-parser"
import { ChevronLeft, ChevronRight, Magnify } from "mdi-material-ui"
import excerptHtml from "excerpt-html"

// import app components
import Edges from "../../components/Edges"
import introduction from "../../components/introduction/Introduction"
import Layout from "../../components/Layout"
import Link from "../../components/Link"

import SimplePagination from "../../components/SimplePagination"

const blocks = {
  introduction,
}
const Template = (props) => {
  const {
    data: {
      wpPage: {
        seo,
        template: { acf },
        uri: basePath,
      },
      allWpPost: { nodes: posts },
    },

    pageContext,

    pageContext: { postsPerPage, totalPages: numberOfPages, currentPage: page },
  } = props

  return (
    <Layout {...props} seo={seo}>
      <Box bg={"bgWhite"}>
        <Edges size="md">
          <Box pb={"100px"}>
            <Heading as="h1" children={"BLOG"} py={"75px"} />
            <Box
              sx={{
                display: ["flex", "grid"],
                flexDirection: "column",
                gridTemplateColumns: "1fr 1fr",
                "@media (min-width:1024px)": {
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                },
                gridTemplateRows: "auto",
                gap: "10px",
              }}
            >
              {posts &&
                posts.map((o, i) => {
                  const imageData = getImage(o.acf.content.image.localFile)

                  return (
                    <Box
                      key={i}
                      sx={{
                        "@media (min-width:1024px)": {
                          gridColumnStart:
                            i === 0 ? "span 6" : i < 5 ? "span 3" : "span 2",
                        },
                      }}
                    >
                      <Link to={o.uri}>
                        <Box
                          sx={{
                            position: "relative",
                            height: "100%",
                            width: "100%",
                            mb: ["30px", "50px"],
                            ".gatsby-image-wrapper": {
                              width: "100%",
                              height: "300px",
                              "@media (min-width:1024px)": {
                                height:
                                  i === 0 ? "585px" : i < 5 ? "385px" : "300px",
                              },
                            },
                          }}
                        >
                          {o.acf.content.image && imageData && (
                            <GatsbyImage
                              image={imageData}
                              alt={o.acf.content.image.altText}
                            />
                          )}

                          <Box
                            sx={{
                              pt: "50px",
                              pb: "30px",
                              h3: { pb: "30px" },
                              px: "30px",
                            }}
                          >
                            {o.date && (
                              <Text
                                variant="styles.dateText"
                                sx={{
                                  mt: ["30px", "50px"],
                                }}
                              >
                                {moment(o.date).format("MMMM DD/YY")}
                              </Text>
                            )}
                            <Box
                              sx={{
                                display: "block",
                                "@media (min-width:1024px)": {
                                  display: [
                                    "block",
                                    i === 0 ? "flex" : "block",
                                  ],
                                },

                                pt: "25px",
                              }}
                            >
                              {o.title && (
                                <Heading
                                  variant="styles.archiveHeader"
                                  sx={{
                                    pb: "30px",
                                    "@media (min-width:1024px)": {
                                      width: ["100%", i === 0 ? "50%" : "100%"],
                                      my: i === 0 ? "auto" : "null",
                                      pr: "30px",
                                    },
                                  }}
                                >
                                  {Parser(o.title)}
                                </Heading>
                              )}
                              {o.acf.content.text && (
                                <Text
                                  sx={{
                                    p: { pr: ["20px", "30px"] },
                                    "@media (min-width:1024px)": {
                                      display: i > 4 ? "none" : "null",
                                      width: ["100%", i === 0 ? "50%" : "100%"],
                                    },
                                  }}
                                >
                                  {excerptHtml(o.acf.content.text, {
                                    pruneLength: 200,
                                  })}
                                </Text>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Link>
                    </Box>
                  )
                })}
            </Box>
            <SimplePagination
              numberOfPages={numberOfPages}
              page={page}
              basePath={basePath}
            />
          </Box>
        </Edges>
      </Box>
    </Layout>
  )
}

export const Query = graphql`
  query PostArchive($id: String!, $offset: Int!, $postsPerPage: Int!) {
    wpPage(id: { eq: $id }) {
      id
      title
      uri
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
      template {
        ... on WpTemplate_ArchivePost {
          templateName
          acf {
            fieldGroupName
            content {
              flex {
                ... on WpTemplate_ArchivePost_Acf_Content_Flex_Introduction {
                  fieldGroupName
                  backgroundcolor
                  text
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 900
                          placeholder: BLURRED
                          quality: 100
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        title
        uri
        date
        acf {
          content {
            text
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 900
                    placeholder: BLURRED
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Template
